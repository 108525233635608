@font-face {
  font-family: 'Friday';
  src: url($cdnURL + '/fonts/Friday-Regular.ttf') format("opentype");
  font-display: swap;
}

@font-face {
  font-family: 'Didact Gothic';
  src: url($cdnURL + '/fonts/DidactGothic-Regular.ttf') format("opentype");
  font-display: swap;
}
@font-face {
  font-family: 'Yiggivoo';
  src: url($cdnURL + '/fonts/Yiggivoo-UC.ttf') format("opentype");
  font-display: swap;
}

@font-face {
  font-family: 'Cyntho';
  src: url($cdnURL + '/fonts/Cyntho-Regular.otf') format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Savoye";
  src: url($cdnURL + "/fonts/SavoyeLetPlain-01.ttf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: "Cera";
  src: url($cdnURL + "/fonts/Cera-GR-Light.ttf") format("opentype");
}



@media screen and (min-width: 1921px) {
  .friday {
    &H1 {
      font-family: 'Friday', Arial, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 2.631vw;
      line-height: 2.917vw;
      letter-spacing: -0.04em;
    }

    &H2 {
      font-family: 'Friday', Arial, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 1.974vw;
      line-height: 2.083vw;
      /* identical to box height, or 106% */

      letter-spacing: -0.04em;
    }

    &H3 {
      /* H3 - Friday 28.43 / 32 */

      font-family: 'Friday', Arial, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 1.481vw;
      line-height: 1.667vw;
      /* identical to box height, or 113% */

      letter-spacing: -0.04em;
    }

    &H4 {
      /* H4 - Friday 21.33 / 24 */

      font-family: 'Friday', Arial, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 1.111vw;
      line-height: 1.25vw;
      /* identical to box height, or 113% */

      letter-spacing: -0.04em;
    }

    &P {
      /* Body Friday 16 / 24 */

      font-family: 'Friday', Arial, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 0.833vw;
      line-height: 1.25vw;
      /* identical to box height, or 150% */

      letter-spacing: -0.04em;
    }

    &Span {
      /* Hint - Friday 12 / 16 */

      font-family: 'Friday', Arial, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 0.625vw;
      line-height: 0.833vw;
      /* identical to box height, or 133% */

      letter-spacing: -0.04em;
    }
  }

  .didactic {
    &H1 {
      /* H1 - Didact Gothic 50.52 / 64 */

      font-family: 'Didact Gothic', Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 2.631vw;
      line-height: 3.333vw;
      /* identical to box height, or 127% */

      letter-spacing: -0.03em;
    }

    &H2 {
      /* H2 - Didact Gothic 37.9 / 48 */

      font-family: 'Didact Gothic', Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 1.974vw;
      line-height: 2.5vw;
      /* identical to box height, or 127% */

      letter-spacing: -0.03em;
    }

    &H3 {
      /* H3 - Didact Gothic 28.43 / 40 */

      font-family: 'Didact Gothic', Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 1.481vw;
      line-height: 2.083vw;
      /* identical to box height, or 141% */

      letter-spacing: -0.03em;
    }

    &H4 {
      /* H4 - Didact Gothic 21.33 / 32 */

      font-family: 'Didact Gothic', Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 1.111vw;
      line-height: 1.667vw;
      /* identical to box height, or 150% */

      letter-spacing: -0.03em;
    }

    &P {
      /* Body - Didact Gothic 16 / 24 */

      font-family: 'Didact Gothic', Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 0.833vw;
      line-height: 1.25vw;
      /* identical to box height, or 150% */

      letter-spacing: -0.03em;
    }

    &Span {
      /* Hint - Didact Gothic 12 / 16 */

      font-family: 'Didact Gothic', Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 0.625vw;
      line-height: 0.833vw;
      /* identical to box height, or 133% */

      letter-spacing: -0.03em;
    }
  }
}


@media screen and (max-width: 1920px) {
  .friday {
    &H1 {
      /* Desktop/Friday H1 */

      font-family: 'Friday', Arial, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 50.5174px;
      line-height: 56px;
      /* identical to box height, or 111% */

      letter-spacing: -0.04em;
    }

    &H2 {
      /* H2 - Friday 37.9 / 40 */

      font-family: 'Friday', Arial, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 37.8975px;
      line-height: 40px;
      /* identical to box height, or 106% */

      letter-spacing: -0.04em;
    }

    &H3 {
      /* H3 - Friday 28.43 / 32 */

      font-family: 'Friday', Arial, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 28.4302px;
      line-height: 32px;
      /* identical to box height, or 113% */

      letter-spacing: -0.04em;
    }

    &H4 {
      /* H4 - Friday 21.33 / 24 */

      font-family: 'Friday', Arial, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 21.328px;
      line-height: 24px;
      /* identical to box height, or 113% */

      letter-spacing: -0.04em;
    }

    &P {
      /* Body Friday 16 / 24 */

      font-family: 'Friday', Arial, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      letter-spacing: -0.04em;
    }

    &Span {
      /* Hint - Friday 12 / 16 */

      font-family: 'Friday', Arial, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12.003px;
      line-height: 16px;
      /* identical to box height, or 133% */

      letter-spacing: -0.04em;
    }
  }

  .didactic {
    &H1 {
      /* H1 - Didact Gothic 50.52 / 64 */

      font-family: 'Didact Gothic', Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 50.5174px;
      line-height: 64px;
      /* identical to box height, or 127% */

      letter-spacing: -0.03em;
    }

    &H2 {
      /* H2 - Didact Gothic 37.9 / 48 */

      font-family: 'Didact Gothic', Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 37.8975px;
      line-height: 48px;
      /* identical to box height, or 127% */

      letter-spacing: -0.03em;
    }

    &H3 {
      /* H3 - Didact Gothic 28.43 / 40 */

      font-family: 'Didact Gothic', Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 28.4302px;
      line-height: 40px;
      /* identical to box height, or 141% */

      letter-spacing: -0.03em;
    }

    &H4 {
      /* H4 - Didact Gothic 21.33 / 32 */

      font-family: 'Didact Gothic', Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 21.328px;
      line-height: 32px;
      /* identical to box height, or 150% */

      letter-spacing: -0.03em;
    }

    &P {
      /* Body - Didact Gothic 16 / 24 */

      font-family: 'Didact Gothic', Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height, or 150% */

      letter-spacing: -0.03em;
    }

    &Span {
      /* Hint - Didact Gothic 12 / 16 */

      font-family: 'Didact Gothic', Arial, Helvetica, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12.003px;
      line-height: 16px;
      /* identical to box height, or 133% */

      letter-spacing: -0.03em;
    }
  }
}

@media screen and (max-width: 1440px) {
  .friday {
    &H1 {
      font-size: 41.05px;
      letter-spacing: -2px;
      font-weight: 400;
      font-family: 'Friday', Arial, sans-serif;
      line-height: 48px;
    }

    &H2 {
      font-size: 37.9px;
      letter-spacing: -1.5px;
      font-weight: 400;
      font-family: 'Friday', Arial, sans-serif;
      line-height: 40px;
    }

    &H3 {
      font-size: 23.1px;
      letter-spacing: -1.12px;
      font-weight: 400;
      font-family: 'Friday', Arial, sans-serif;
      line-height: 32px;
    }

    &H4 {
      font-size: 21.33px;
      letter-spacing: -0.84px;
      font-weight: 400;
      font-family: 'Friday', Arial, sans-serif;
      line-height: 24px;
    }

    &P {
      font-size: 13px;
      letter-spacing: -0.63px;
      font-weight: 400;
      font-family: 'Friday', Arial, sans-serif;
      line-height: 22px;
    }

    &Span {
      font-size: 0.75em;
      letter-spacing: -0.47px;
      font-weight: 400;
      font-family: 'Friday', Arial, sans-serif;
      line-height: 16px;
    }
  }

  .didactic {
    &H1 {
      font-size: 41.05px;
      letter-spacing: -2px;
      font-weight: 400;
      font-family: 'Didact Gothic', Arial, Helvetica, sans-serif;
      line-height: 56px;
    }

    &H2 {
      font-size: 37.9px;
      letter-spacing: -1.5px;
      font-weight: 400;
      font-family: 'Didact Gothic', Arial, Helvetica, sans-serif;
      font-style: normal;
      line-height: 48px;
    }

    &H3 {
      font-size: 23.1px;
      letter-spacing: -1.12px;
      font-weight: 400;
      font-family: 'Didact Gothic', Arial, Helvetica, sans-serif;
      line-height: 32px;
    }

    &H4 {
      font-size: 17.33px; //ex-21.33 (changed cause design)
      letter-spacing: -0.84px;
      font-weight: 400;
      font-family: 'Didact Gothic', Arial, Helvetica, sans-serif;
      line-height: 32px;
    }

    &P {
      font-size: 13px;
      letter-spacing: -0.63px;
      font-weight: 400;
      line-height: 24px;
      font-family: 'Didact Gothic', Arial, Helvetica, sans-serif;
    }

    &Span {
      font-size: 0.75em;
      letter-spacing: -0.47px;
      font-weight: 400;
      line-height: 16px;
      font-family: 'Didact Gothic', Arial, Helvetica, sans-serif;
    }
  }
}
