$cdnURL: "https://rest-filestore.whiteclover.uk/files/whiteclover.io";
@import "base";
@import "typography";


::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #62445b;
}

::-webkit-scrollbar-thumb:hover {
    background: #62445b;
}

:root {
    --rt-color-white: #000!important;
    --rt-color-dark: #F9AC80!important;
    --rt-color-success: #8dc572;
    --rt-color-error: #be6464;
    --rt-color-warning: #f0ad4e;
    --rt-color-info: #337ab7;
    --rt-opacity: 0.9;

    --swiper-theme-color: #63435B !important;
}

@font-face {
    font-family: 'Friday-Regular';
    src: url($cdnURL + '/fonts/Friday-Regular.ttf') format("opentype");
    font-display: swap;
}

@font-face {
    font-family: 'Cera_GR_Light';
    src: url($cdnURL + '/fonts/Cera_GR_Light.otf') format("opentype");
    font-display: swap;
}

@font-face {
    font-family: 'Cera_GR_Thin';
    src: url($cdnURL + '/fonts/Cera_GR_Thin.otf') format("opentype");
    font-display: swap;
}

@font-face {
    font-family: 'Cera_GR_Medium';
    src: url($cdnURL + '/fonts/Cera_GR_Medium.otf') format("opentype");
    font-display: swap;
}

@font-face {
    font-family: 'SavoyeLetPlain';
    src: url($cdnURL + '/fonts/SavoyeLetPlain-01.ttf') format("opentype");
    font-display: swap;
}

@font-face {
    font-family: 'EBGaramond';
    src: url($cdnURL + '/fonts/EBGaramond-Italic.ttf') format("opentype");
    font-display: swap;
}

@font-face {
    font-family: 'DidactGothic-Regular';
    src: url($cdnURL + '/fonts/DidactGothic-Regular.ttf') format("opentype");
    font-display: swap;
}

@font-face {
    font-family: 'fonde';
    src: url($cdnURL + '/fonts/fonde.ttf') format("opentype");
    font-display: swap;
}

@font-face {
    font-family: 'Catamount';
    src: url($cdnURL + '/fonts/CatamountSignature.ttf') format("opentype");
    font-display: swap;
}

html,
body {
    height: 100%;
    padding: 0;
    margin: 0;
    font-family: Friday, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    //scroll-behavior: smooth !important;
    background-color: #fdfcfa;
    background-image: url($cdnURL + "/newassets/background-texture-full.jpg");
    background-repeat: repeat;
}

body {
    //scroll-behavior: smooth !important;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    text-rendering: optimizeLegibility;
    overflow-x: hidden;
    overflow-y: scroll;
    touch-action: manipulation;
    -webkit-user-drag: none;
    -ms-content-zooming: none;
    word-wrap: break-word;
    overscroll-behavior-y: none;
    text-size-adjust: none;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.next-image > div {
    margin: 0 auto !important;
}

.mainClass {
    width: 100vw;
    padding-top: 8vw;
    padding-bottom: 8vw;
}


.secondaryClass {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1.6vw;
    margin: 0 auto;
    padding: 0 9vw;
}

.imageLeftClass {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    img {
        border-radius: 1.6vw;
        width: 26vw;
    }
}

.imageRightClass {
    display: flex;
    align-self: center;
    img {
        width: 29vw;
    }
}

.text-light {
    font-family: Cera_GR_Thin, serif !important;
    font-size: 1.25vw !important;
}

.text-darkCoral {
    color: $darkCoral;
}

.text-right {

}

.arrow {
    max-width: 25vw;
    height: 6px;
    margin: 1em auto;
    border: 0;
    position: relative;
    overflow: hidden;
    background-image: linear-gradient(#FFFFFF 2px, #000000 2px, #000000 4px, #FFFFFF 4px);
}

.arrow:before,
.arrow:after {
    content: "";
    position: absolute;
}

.arrow:after {
    right: -3px;
    border: 3px solid;
    border-color: #FFFFFF #FFFFFF #FFFFFF #000000;
}

.warning > div {
    background: $error-state;
}

@media screen and (max-width: 1920px) {
    .imageLeftClass, .imageRightClass {
        img {
            width: 23vw;
        }
        .imgChurch {
            width: 26vw;
        }
        .imgProgram {
            width: 36vw;
        }
    }
}

@media screen and (max-width: 1366px) {
    .secondaryClass{
        padding: 0 12vw;
    }
    .mainClass {
        padding-bottom: 0;
    }
}

@media screen and (max-width: 1100px) {
    .mainClass {
        padding-top: 14vw;
    }

    .secondaryClass {
        padding: 0 9vw;
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .text-light {
        font-size: 3vw!important;
    }

    .imageLeftClass, .imageRightClass {
        margin-bottom: 3.6vw;
        justify-content: flex-end;
        img {
            width: 50vw;
        }
        .imgChurch {
            width: 50vw;
            position: relative;
        }
        .imgProgram {
            width: 100%;
            position: relative;
        }
    }
}

@media screen and (max-width: 768px) {
    .mainClass {
        padding-top: 14.5vw;
    }

    .secondaryClass {
        padding: 0 8vw;
    }

    .imageRightClass {
        .imgProgram {
            margin: 0 auto;
        }
    }
}


@media screen and (max-width: 450px) {
    .secondaryClass {
        padding: 0 2rem;
    }
    .text-light {
        font-size: 1rem!important;
    }

    .imageLeftClass, .imageRightClass {
        .imgChurch {
            width: 70vw;
        }
    }
}




