$wed-beige: #f7eee8;
$darkCoral: #d1563f;


$error-state: #D54F68;

$main-strong-accent: #281B24;
$main-operational-accent: #A9A4A7;

$secondary-strong-accent: #63435B;
$secondary-operational-accent: #63435B;
$secondary-hover-accent: #63435B;

$brand-strong-accent: #F78C50;
$brand-operational-accent: #F9AC80;
$brand-hover-accent: #F9AC80;


@mixin transition-all($time) {
  transition: all $time ease-in-out;
}

@mixin transition($property, $time) {
  transition: $property $time ease-in-out;
}